import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'stores/app';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export function NotificationBar() {
  const { t } = useTranslation();
  const { notification, closeNotificationBar } = useAppStore();

  const getColor = (status: 'success' | 'error' | 'warning') => {
    if (status === 'success') return 'primary.main';
    if (status === 'warning') return 'warning.main';
    if (status === 'error') return 'error.main';
  };

  const showDuration = notification.type === 'success' ? 2000 : 4000;

  return (
    <Snackbar
      sx={{ pointerEvents: 'none' }}
      open={notification.isShown}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={showDuration}
      TransitionComponent={SlideTransition}
      onClose={closeNotificationBar}
    >
      <Alert
        sx={{ bgcolor: 'background.paper', color: getColor(notification.type) }}
        severity={notification.type}
        variant="outlined"
        onClose={closeNotificationBar}
      >
        {notification.messageKey
          ? t(`notificationBar.custom.${notification.messageKey}`)
          : t(`notificationBar.default.${notification.type}`)}
      </Alert>
    </Snackbar>
  );
}
