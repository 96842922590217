import { client } from 'services/graphql';
import { CardTemplate } from 'stores/card';
import {
  deleteCardsMutation,
  updateCardsLearningLevelMutation,
  createCardsMutation,
  updateCardsMutation,
  addCardToFavoritesMutation,
  removeCardFromFavoritesMutation,
} from './mutations';

export const updateCardsLearningLevel = async (userSetId: string, ids: string[], level: number) =>
  await client.request(updateCardsLearningLevelMutation, {
    userSetId,
    userCardIds: ids,
    learningLevel: level,
  });

type CreationCardTemplate = Omit<CardTemplate, 'id'>;

export const createCards = async (userSetId: string, cards: CreationCardTemplate[]) =>
  await client.request(createCardsMutation, { userSetId, cards });

export const updateCards = async (userSetId: string, cards: CardTemplate[]) =>
  await client.request(updateCardsMutation, { userSetId, cards });

export const deleteCards = async (userSetId: string, ids: string[]) =>
  await client.request(deleteCardsMutation, { userSetId, userCardIds: ids });

export const addCardToFavorites = async (cardId: string) =>
  await client.request(addCardToFavoritesMutation, { userCardId: cardId });

export const removeCardFromFavorites = async (cardId: string) =>
  await client.request(removeCardFromFavoritesMutation, { userCardId: cardId });
