import { Box, Typography, TableCell, Checkbox, IconButton } from '@mui/material';
import { EditOutlined, Delete } from '@mui/icons-material';
import { useCardStore } from 'stores/card';
import { StudySetCard } from 'stores/studySet';
import { ProgressLine } from 'components/commons';
import { AddOnsCell } from './AddOnsCell';
import { Favorite } from './Favorite';

interface TermsTableRowProps {
  card: StudySetCard;
  selected: Set<string>;
  isOwner: boolean;
  isParticipant: boolean;
  onDelete: (id: string) => void;
}

export function TermsTableRow({
  card,
  selected,
  isOwner,
  isParticipant,
  onDelete,
}: TermsTableRowProps) {
  const { openCardManager } = useCardStore();

  return (
    <>
      {(isOwner || isParticipant) && (
        <TableCell padding="checkbox" sx={{ p: 1, pr: 0 }}>
          <Checkbox id={card.id} checked={selected.has(card.id)} />
        </TableCell>
      )}
      <TableCell sx={{ p: 1, pl: !isOwner && !isParticipant ? 3 : 1 }}>
        {card.terms?.[0]}
        {card.terms?.length > 1 && (
          <Typography variant="body2" color="textSecondary">
            {card.terms.slice(1).join(', ')}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        {card.relatedTerms?.[0]}
        {card.relatedTerms?.length > 1 && (
          <Typography variant="body2" color="textSecondary">
            {card.relatedTerms.slice(1).join(', ')}
          </Typography>
        )}
      </TableCell>
      <AddOnsCell examples={card.examples} definitions={card.definitions} />
      {(isOwner || isParticipant) && (
        <TableCell sx={{ p: 1 }}>
          <Box sx={{ width: 100, mt: 2 }}>
            <ProgressLine colored size="small" progress={card.learningLevel * 10} />
          </Box>
        </TableCell>
      )}
      {isOwner && (
        <TableCell
          sx={{
            position: 'sticky',
            right: 0,
            minWidth: 120,
            textAlign: 'right',
            p: 1,
            backgroundColor: 'background.paper',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <IconButton
              size="small"
              sx={{ border: '1px solid', borderColor: 'primary.main', mr: 1 }}
              onClick={() => openCardManager([card.id])}
            >
              <EditOutlined color="primary" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ border: '1px solid', borderColor: 'error.main', mr: 0.5 }}
              onClick={() => onDelete(card.id)}
            >
              <Delete color="error" />
            </IconButton>
            <Favorite cardId={card.id} isFavorite={card.isFavorite} />
          </Box>
        </TableCell>
      )}
    </>
  );
}
