import { User, UserRole } from './user.types';

export const getInitials = (user: User): string => {
  const { firstName, lastName } = user;
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }
  if (firstName) return firstName?.substring(0, 2);
  if (lastName) return lastName?.substring(0, 2);
  return '=)';
};

export const isUserAdmin = (user: User | null): boolean => {
  if (!user) return false;
  return user.role === UserRole.Admin;
};
