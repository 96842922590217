import { graphql } from 'services/graphql';

export const getAuthUserQuery = graphql`
  query AuthUserQuery {
    user {
      id
      firstName
      lastName
      username
      language
      role
    }
  }
`;
