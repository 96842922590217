import { IconButton } from '@mui/material';
import { Star } from '@mui/icons-material';
import { useCardStore } from 'stores/card';

interface FavoriteProps {
  cardId: string;
  isFavorite: boolean;
}

export function Favorite({ cardId, isFavorite }: FavoriteProps) {
  const { addToFavorites, removeFromFavorites } = useCardStore();

  const handleClick = () => {
    if (isFavorite) {
      removeFromFavorites(cardId);
    } else {
      addToFavorites(cardId);
    }
  };

  return (
    <IconButton size="small" sx={{ p: 0 }} onClick={handleClick}>
      <Star color={isFavorite ? 'primary' : 'secondary'} sx={{ fontSize: 28 }} />
    </IconButton>
  );
}
