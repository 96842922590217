import { STUDY_SETS_LIMIT } from 'constants/index';
import { useAuthStore } from 'stores/auth';
import { useUserStore } from './user.store';
import { isUserAdmin } from './user.utils';

export const useUserStudySetsLimitation = (): boolean => {
  const { sets } = useUserStore();
  const { user } = useAuthStore();

  if (isUserAdmin(user)) return true;

  const userSets = sets?.filter(({ owner }) => owner.id === user?.id);
  const setsAmount = userSets?.length || 0;
  return setsAmount < STUDY_SETS_LIMIT;
};
