export enum UserRole {
  Admin = 'admin',
  FreePlanUser = 'free_plan_user',
}
export interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  language?: string;
  role?: UserRole;
}

export interface UserStatistics {
  points: number;
  levelVocabulary: string;
  numLearnedCards: number;
}

export interface RequestUserMeta {
  isJoinable: boolean;
  isParticipant: boolean;
}

export interface Trophy {
  number?: number;
  info: {
    version?: number;
    type: string;
    title: string;
    name: string;
    url: string;
    rarity: number;
  };
}

export interface UserSetCard {
  id: string;
  isActive: boolean;
  level: string;
  name: string;
  numLearnedCards: number;
  progress: number;
  totalCards: number;
  owner: User;
  requestUserMeta: RequestUserMeta;
}

export interface UserSettings {
  language: string;
  timezoneOffset: number;
  interval: number | null;
  startTime: string;
  endTime: string;
  trainingEnabled: boolean;
}

export interface UserSettingUtil {
  id: keyof UserSettings;
  name: string;
  inputLabel: string;
  defaultState?: string;
  options: { value: string | number; label: string }[];
}

export interface UpdateIntervalTrainingSettings {
  trainingEnabled?: boolean;
  interval?: number;
  startTime?: string;
  endTime?: string;
  timezoneOffset?: number;
}

export interface UpdateUserSettings {
  language: string;
}

export type SettingItemValue = string | number | boolean;
